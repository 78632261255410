import React, { useState } from "react"
import { Link } from "gatsby"

import Logo from "../components/logo"
import ResetForm from "../components/forms/ResetForm"
import LoginLayout from "../components/login-layout"
import SplashImage from "../components/splash-image"

function ActivationPage() {
  const [success, setSuccessFlag] = useState(false)

  return (
    <LoginLayout>
      <section className="flex flex-col md:flex-row h-screen items-center">
        <div className="bg-blue-600 hidden lg:block w-full md:w-1/2 xl:w-2/3 h-screen">
          <SplashImage />
        </div>

        <div className="bg-white w-full md:max-w-md lg:max-w-full md:mx-auto md:mx-0 md:w-1/2 xl:w-1/3 h-screen px-6 lg:px-16 xl:px-12 flex items-center justify-center">
          <div className="w-full h-100">
            <Link to="/" className="flex flex-shrink-0 items-center">
              <Logo />
              <h1 className="serif xl:text-xl font-bold">
                British Society <i className="font-normal text-gray-700">of</i>{" "}
                <span className="block">Skeletal Radiologists</span>
              </h1>
            </Link>

            <h1 className="text-xl md:text-2xl font-bold leading-tight mt-12 mb-6">
              Activate Your Online Account
            </h1>

            {!success ? (
              <p className="mb-8 bg-yellow-200 border-yellow-400 border-2 rounded p-4">
                To activate your online BSSR account please enter your email
                address in the field below and click the 'Send Email' button.
                You will receive an email with instructions to set a password
                for your new online account.
              </p>
            ) : (
              <>
                <p className="mb-8 bg-green-300 border-green-500 border-2 rounded p-4">
                  An email has been sent to the email address that you provided.
                  This email contains further instructions to help you set your
                  new password.
                </p>
                <hr className="my-6 border-gray-300 w-full" />

                <p className="my-4">
                  <Link to="/login" className="text-indigo-700 font-semibold">
                    Go to the login page
                  </Link>
                </p>
              </>
            )}

            <ResetForm submitCallback={() => setSuccessFlag(true)} />
          </div>
        </div>
      </section>
    </LoginLayout>
  )
}

export default ActivationPage
